$(document).ready(function() {
    let activeTab = $('.tabs__item--active').data('target-tab');
    $('.tabs__content [data-tab="' + activeTab + '"]').show();

    function switchTab($tabsContainer, $currentItem, direction) {
        let $newItem;

        if (direction === 'prev') {
            $newItem = $currentItem.prev('.tabs__item');
        } else if (direction === 'next') {
            $newItem = $currentItem.next('.tabs__item');
        }

        if ($newItem.length > 0) {
            $currentItem.removeClass('tabs__item--active');
            $newItem.addClass('tabs__item--active');
            let targetTab = $newItem.data('target-tab');
            $tabsContainer.find('.tabs__content [data-tab]').hide();
            $tabsContainer.find('.tabs__content [data-tab="' + targetTab + '"]').show();
        }
    }

    $('.tabs__btn--prev').on('click', function() {
        let $tabsContainer = $(this).closest('.tabs');
        let $activeItem = $tabsContainer.find('.tabs__item--active');
        switchTab($tabsContainer, $activeItem, 'prev');
    });

    $('.tabs__btn--next').on('click', function() {
        let $tabsContainer = $(this).closest('.tabs');
        let $activeItem = $tabsContainer.find('.tabs__item--active');
        switchTab($tabsContainer, $activeItem, 'next');
    });

    $('.tabs__item').on('click', function() {
        let $tabsContainer = $(this).closest('.tabs');
        let $itemsContainer = $tabsContainer.find('.tabs__items');
        $itemsContainer.find('.tabs__item').removeClass('tabs__item--active');
        $(this).addClass('tabs__item--active');
        let targetTab = $(this).data('target-tab');
        $tabsContainer.find('.tabs__content [data-tab]').hide();
        $tabsContainer.find('.tabs__content [data-tab="' + targetTab + '"]').show();
    });
});