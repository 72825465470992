$(document).ready(function() {
    $(document).on('click', '.form-field__clear', function() {
        var $field = $(this).closest('.form-field');
        $field.addClass('form-field--disabled');
        $field.removeClass('form-field--active');

        var $input = $field.find('.form-field__input, .form-field__textarea');
        if ($input.length) {
            var defaultValue = $input.data('value');
            $input.val(defaultValue);
            return;
        }

        var $input = $field.find('.form-field__search');
        if ($input.length) {
            $input.val('');

        }
    });

    $(document).on('click', '.form-field__action-save', function() {
        var $field = $(this).closest('.form-field');
        var $input = $field.find('.form-field__input, .form-field__textarea');
        var newValue = $input.val();
        $input.data('value', newValue);
        $field.addClass('form-field--disabled');
    });

    $(document).on('keypress', '.form-field__group--editable .form-field__input', function(e) {
        if (e.which === 13) {
            e.preventDefault();
            $(this).closest('.form-field').find('.form-field__action-save').click();
            $(this).blur();
        }
    });

    $(document).on('focus', '[data-mask]', function() {
        var mask = $(this).attr('data-mask');
        $(this).inputmask(mask, {autoclear: false, clearMaskOnLostFocus: false});
    });

    $(document).on('click', '.form-field__edit, .form-field--disabled .form-field__group--editable', function() {
        var $field = $(this).closest('.form-field');
        $field.removeClass('form-field--disabled');
        var $input = $field.find('.form-field__group:not(.form-field__group--select) .form-field__input');

        if ($input.length) {
            $input.focus();
            var inputValue = $input.val();
            $input[0].setSelectionRange(inputValue.length, inputValue.length);
        }

        var $textarea = $field.find('.form-field__textarea');

        if ($textarea.length) {
            $textarea.focus();
            var textareaValue = $textarea.val();
            $textarea[0].setSelectionRange(textareaValue.length, textareaValue.length);
        }
    });

    $(document).on('click', '.header-checkbox', function() {
        var headerTitle = $(this).closest('.card-form__title');

        if ($(this).is(':checked')) {
            headerTitle.addClass('card-form__title--active');
        } else {
            headerTitle.removeClass('card-form__title--active');
        }
    });

    $(document).on('focus', '.form-field__search', function() {
        var formField = $(this).closest('.form-field');

        formField.removeClass('form-field--disabled');
        formField.addClass('form-field--active');
    });

    function resizeTextarea() {
        $(this).css('height', 'auto');
        $(this).css('height', this.scrollHeight + 'px');
    }

    $(document).on('input', 'textarea', resizeTextarea);
    $('textarea').each(resizeTextarea);
});
