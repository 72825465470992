$(document).ready(function() {
    $('[data-chart]').each(function(i, el) {
        const chartData = eval(el.dataset.chartData);

        const legendContainer = document.createElement('div');
        legendContainer.classList.add('chart-legend');
        legendContainer.setAttribute('data-chart-legend-container', '');
        el.appendChild(legendContainer);

        const chartContainer = document.createElement('div');
        chartContainer.setAttribute('data-chart-container', '');
        el.appendChild(chartContainer);

        const chartCanvas = document.createElement('canvas');
        chartCanvas.setAttribute('data-chart-canvas', '');
        chartContainer.appendChild(chartCanvas);

        const getOrCreateLegendList = (chart, selector) => {
            const legendContainer = el.querySelector(selector);
            let listContainer = legendContainer.querySelector('div');

            if (!listContainer) {
                listContainer = document.createElement('div');
                listContainer.classList.add('chart-legend');
                legendContainer.appendChild(listContainer);
            }

            return listContainer;
        };

        const htmlLegendPlugin = {
            id: 'htmlLegend',
            afterUpdate(chart, args, options) {
                const ul = getOrCreateLegendList(chart, options.containerSelector);

                // Remove old legend items
                while (ul.firstChild) {
                    ul.firstChild.remove();
                }

                // Reuse the built-in legendItems generator
                const items = chart.options.plugins.legend.labels.generateLabels(chart);

                items.forEach(item => {
                    const li = document.createElement('div');
                    li.classList.add('chart-legend__item');

                    li.onclick = () => {
                        const {type} = chart.config;
                        if (type === 'pie' || type === 'doughnut') {
                            // Pie and doughnut charts only have a single dataset and visibility is per item
                            chart.toggleDataVisibility(item.index);
                        } else {
                            chart.setDatasetVisibility(item.datasetIndex, !chart.isDatasetVisible(item.datasetIndex));
                        }
                        chart.update();
                    };

                    // Color box
                    const boxSpan = document.createElement('span');
                    boxSpan.classList.add('chart-legend__icon');
                    boxSpan.style.backgroundColor = item.fillStyle;
                    boxSpan.style.backgroundColor = item.strokeStyle;

                    // Text
                    const textContainer = document.createElement('span');
                    textContainer.classList.add('chart-legend__label');
                    textContainer.style.textDecoration = item.hidden ? 'line-through' : '';

                    const text = document.createTextNode(item.text);
                    textContainer.appendChild(text);

                    li.appendChild(boxSpan);
                    li.appendChild(textContainer);
                    ul.appendChild(li);
                });
            },
        };

        const getOrCreateTooltip = (chart) => {
            let tooltipEl = chart.canvas.parentNode.querySelector('div');

            if (!tooltipEl) {
                tooltipEl = document.createElement('div');
                tooltipEl.classList.add('chart-tooltip');

                const table = document.createElement('table');
                table.classList.add('chart-tooltip__table');
                tooltipEl.appendChild(table);

                chart.canvas.parentNode.appendChild(tooltipEl);
            }

            return tooltipEl;
        };

        const externalTooltipHandler = (context) => {
            const {chart, tooltip} = context;
            const tooltipEl = getOrCreateTooltip(chart);

            if (tooltip.opacity === 0) {
                tooltipEl.style.opacity = 0;
                return;
            }

            if (tooltip.body) {
                const titleLines = tooltip.title || [];
                const bodyLines = tooltip.body.map(b => b.lines);

                const tableHead = document.createElement('thead');

                titleLines.forEach(title => {
                    const tr = document.createElement('tr');
                    tr.classList.add('chart-tooltip__tr');

                    const th = document.createElement('th');
                    th.classList.add('chart-tooltip__th');
                    th.classList.add('chart-tooltip__title');
                    const text = document.createTextNode(title);

                    th.appendChild(text);
                    tr.appendChild(th);
                    tableHead.appendChild(tr);
                });

                const tableBody = document.createElement('tbody');
                bodyLines.forEach((body, i) => {
                    const colors = tooltip.labelColors[i];

                    const span = document.createElement('span');
                    span.classList.add('chart-tooltip__icon');
                    span.style.background = colors.backgroundColor;
                    span.style.borderColor = colors.borderColor;

                    const tr = document.createElement('tr');
                    tr.classList.add('chart-tooltip__tr');

                    const td = document.createElement('td');
                    td.classList.add('chart-tooltip__td');
                    td.classList.add('chart-tooltip__label-row');

                    const parts = body[0].split(/:\s(.+)/);

                    const labelContainer = document.createElement('div');
                    labelContainer.classList.add('chart-tooltip__label-container');

                    const label = document.createElement('div');
                    label.classList.add('chart-tooltip__label');
                    label.textContent = parts[0];

                    const value = document.createElement('div');
                    value.classList.add('chart-tooltip__value');
                    value.textContent = parts[1];

                    td.appendChild(span);
                    labelContainer.appendChild(label);
                    labelContainer.appendChild(value);
                    td.appendChild(labelContainer);
                    tr.appendChild(td);
                    tableBody.appendChild(tr);
                });

                const tableRoot = tooltipEl.querySelector('table');

                while (tableRoot.firstChild) {
                    tableRoot.firstChild.remove();
                }

                tableRoot.appendChild(tableHead);
                tableRoot.appendChild(tableBody);
            }

            const {offsetLeft: positionX, offsetTop: positionY} = chart.canvas;

            tooltipEl.style.opacity = 1;
            tooltipEl.style.left = positionX + tooltip.caretX + 'px';
            tooltipEl.style.top = positionY + tooltip.caretY + 'px';
        };

        const config = {
            type: 'line',
            data: chartData,
            options: {
                responsive: true,
                interaction: {
                    mode: 'nearest',
                },
                stacked: false,
                scales: {
                    x: {
                        grid: {
                            color: '#D1E3F7',
                        },
                    },
                    y: {
                        type: 'linear',
                        display: true,
                        position: 'left',
                        ticks: {
                            stepSize: 1000,
                        },
                        grid: {
                            color: '#D1E3F7',
                        },
                        title: {
                            display: true,
                            text: 'Пользователей, чел',
                            color: '#7C92A7',
                            font: {
                                family: '\'rawline\', sans-serif',
                                size: 13,
                                lineHeight: '16.9px',
                            },
                            padding: {
                                top: 30,
                                left: 0,
                                right: 0,
                                bottom: 0,
                            },
                        },
                    },
                },
                plugins: {
                    legend: {
                        display: false,
                    },
                    htmlLegend: {
                        containerSelector: '[data-chart-legend-container]',
                    },
                    tooltip: {
                        enabled: false,
                        position: 'nearest',
                        external: externalTooltipHandler,
                    },
                },
            },
            plugins: [htmlLegendPlugin],
        };

        new Chart(el.querySelector('[data-chart-canvas]'), config);
    });
});
