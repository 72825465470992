$(document).ready(function() {
    $('.form-field__group--date').each(function() {
        new FormDatepicker(this);
    });
});

class FormDatepicker {
    static START_YEAR = 1900;
    static END_YEAR = 2030;

    constructor(dateFieldElement) {
        this.$group = $(dateFieldElement);
        this.$input = this.$group.find('.form-field__input');
        this.$label = this.$group.find('.form-field__label');
        this.labelText = this.$label.text();
        this.currentValue = this.$input.val();
        this.initializeDateValues();
        this.initDatepicker();
        $(document).on('click', (e) => this.handleDocumentClick(e));
    }

    initializeDateValues() {
        const dateObject = this.currentValue
          ? new Date(this.currentValue.split('.').reverse().join('-'))
          : new Date();

        this.monthText = this.getMonthName(dateObject);
        this.year = dateObject.getFullYear();
        this.initialDate = dateObject;
    }

    initDatepicker() {
        const datepickerHTML = `
            <div class="form-datepicker">
                <div class="form-datepicker__value">
                    ${this.currentValue || this.labelText}
                </div>
                <div class="form-datepicker__card">
                    <div class="form-datepicker__header">
                        <button class="form-datepicker__select">${this.monthText}</button>
                        <button class="form-datepicker__select">${this.year}</button>
                    </div>
                    <div class="form-datepicker__calendar"></div>
                </div>
            </div>
        `;

        this.$label.after(datepickerHTML);
        this.$datepicker = this.$group.find('.form-datepicker__calendar');
        this.$monthButton = this.$group.find('.form-datepicker__select:first');
        this.$yearButton = this.$group.find('.form-datepicker__select:last');
        this.initializeBootstrapDatepicker();
    }

    initializeBootstrapDatepicker() {
        this.$datepicker.datepicker({
            weekStart: 1,
            maxViewMode: 2,
            language: 'ru',
            todayHighlight: true,
            autoclose: true,
        }).
          on('changeDate', (e) => this.handleDateChange(e)).
          on('changeMonth', (e) => this.handleMonthChange(e)).
          on('changeYear', (e) => this.handleYearChange(e));

        if (this.initialDate) {
            this.$datepicker.datepicker('setDate', this.initialDate);
        }

        this.$monthButton.on('click', () => {
            this.toggleMonthSelect();
        });

        this.$yearButton.on('click', () => {
            this.toggleYearSelect();
        });

        this.populateYears();
    }

    toggleMonthSelect() {
        if (this.$monthButton.hasClass('form-datepicker__select--open')) {
            this.closeMonthSelect();
        } else {
            this.openMonthSelect();
        }
    }

    openMonthSelect() {
        try {
            this.closeYearSelect();
            this.$monthButton.addClass('form-datepicker__select--open');
            this.$group.find('.datepicker-days .datepicker-switch').click();
        } catch (e) {

        }
    }

    closeMonthSelect() {
        try {
            this.$monthButton.removeClass('form-datepicker__select--open');
            this.$group.find('.datepicker-months .month.active').click();
        } catch (e) {

        }
    }

    toggleYearSelect() {
        if (this.$yearButton.hasClass('form-datepicker__select--open')) {
            this.closeYearSelect();
        } else {
            this.openYearSelect();
        }
    }

    openYearSelect() {
        try {
            this.closeMonthSelect();
            this.$yearButton.addClass('form-datepicker__select--open');
            this.populateYears();
            this.$group.find('.datepicker-days .datepicker-switch').click();
            this.$group.find('.datepicker-months .datepicker-switch').click();
            this.scrollToActiveYear();
        } catch (e) {

        }
    }

    closeYearSelect() {
        try {
            this.$yearButton.removeClass('form-datepicker__select--open');
            this.$group.find('.datepicker-years .year.active').click();
        } catch (e) {

        }
    }

    populateYears() {
        const $yearTable = this.$datepicker.find('.datepicker-years tbody td');
        let html = '';

        for (let year = FormDatepicker.START_YEAR; year <= FormDatepicker.END_YEAR; year++) {
            html += `<span class="year${this.year == year ? ' active focus' : ''}">${year}</span>`;
        }

        $yearTable.html(html);
    }

    scrollToActiveYear() {
        const $activeYear = this.$datepicker.find('.year.active.focus').first();
        if ($activeYear.length) {
            const $yearTableBody = this.$datepicker.find('.datepicker-years');
            const containerTop = $yearTableBody.offset().top;
            const containerHeight = $yearTableBody.height();
            const activeYearTop = $activeYear.offset().top;
            const activeYearHeight = $activeYear.outerHeight();
            const scrollTop = $yearTableBody.scrollTop();

            const visibleAreaTop = containerTop + scrollTop;
            const visibleAreaBottom = visibleAreaTop + containerHeight;

            if (activeYearTop < visibleAreaTop || activeYearTop + activeYearHeight > visibleAreaBottom) {
                const newScrollTop = scrollTop +
                  (activeYearTop - containerTop - (containerHeight / 2) + (activeYearHeight / 2));
                $yearTableBody.scrollTop(newScrollTop);
            }
        }
    }

    handleDateChange(e) {
        const newDate = e.format('dd.mm.yyyy');
        this.currentValue = newDate;
        this.$input.val(newDate);
        this.$group.find('.form-datepicker__value').text(newDate);
        const [day, month, year] = newDate.split('.');
        const dateObject = new Date(`${year}-${month}-${day}`);
        this.setMonthText(dateObject);
        this.setYear(year);
        this.addDisabledClass();
    }

    handleMonthChange(e) {
        this.setMonthText(e.date);
        this.$monthButton.removeClass('form-datepicker__select--open');
    }

    handleYearChange(e) {
        this.setYear(e.date.getFullYear());
        this.$yearButton.removeClass('form-datepicker__select--open');
        this.$monthButton.addClass('form-datepicker__select--open');
    }

    addDisabledClass() {
        this.$group.closest('.form-field').addClass('form-field--disabled');
    }

    handleDocumentClick(e) {
        if (!this.$group.is(e.target) && this.$group.has(e.target).length === 0) {
            this.addDisabledClass();
        }
    }

    setMonthText(dateObject) {
        this.monthText = this.getMonthName(dateObject);
        this.$group.find('.form-datepicker__header .form-datepicker__select').first().text(this.monthText);
    }

    setYear(year) {
        this.year = year;
        this.$group.find('.form-datepicker__header .form-datepicker__select').last().text(this.year);
    }

    getMonthName(date) {
        const monthNames = [
            'Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь',
            'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь',
        ];
        return monthNames[date.getMonth()];
    }
}