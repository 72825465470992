$(document).ready(function() {
    $(document).on('click', '.form-chips__item', function() {
        const $item = $(this);
        const $formChips = $item.closest('.form-chips');
        const $formField = $formChips.closest('.form-field');
        const $hiddenInput = $formField.find('.form-field__input');

        if ($formField.hasClass('form-field--disabled')) {
            return;
        }

        let selectedValues = JSON.parse($hiddenInput.val()) || [];
        const value = $item.data('value');

        if ($item.hasClass('form-chips__item--selected')) {
            $item.removeClass('form-chips__item--selected');
            selectedValues = selectedValues.filter(val => val !== value);
        } else {
            $item.addClass('form-chips__item--selected');
            if (!selectedValues.includes(value)) {
                selectedValues.push(value);
            }
        }

        $hiddenInput.val(JSON.stringify(selectedValues));
        sortChips($formChips);
    });

    $(document).on('click', function(event) {
        $('.form-field:has(.form-chips)').each(function() {
            const $formField = $(this);
            const $formChips = $formField.find('.form-chips');

            if (!$formChips.is(event.target) && $formChips.has(event.target).length === 0 &&
              !$formChips.hasClass('form-chips--inline')) {
                $formField.addClass('form-field--disabled');
            }
        });
    });
});

function sortChips($formChips) {
    const $items = $formChips.children('.form-chips__item');
    const $selected = $items.filter('.form-chips__item--selected');
    const $notSelected = $items.not('.form-chips__item--selected');

    $formChips.empty();

    $formChips.append($selected);
    $formChips.append($notSelected);
}