$(document).ready(function() {
    $('.filter-input__input--search').on('focus', function() {
        var $input = $(this);
        var $filterInput = $input.closest('.filter-input');
        var $headerLeft = $('.page-card__header-left');

        var inputOffset = $input.offset();
        var headerLeftOffset = $headerLeft.offset();

        var $placeholder = $('<div class="filter-input__placeholder"></div>').css({
            width: $filterInput.outerWidth(),
            height: $filterInput.outerHeight(),
        });

        $filterInput.before($placeholder);

        $filterInput.css({
            position: 'fixed',
            top: inputOffset.top,
            left: headerLeftOffset.left,
            width: inputOffset.left - headerLeftOffset.left + $input.outerWidth(),
            zIndex: 15,
        });
    });

    $('.filter-input__input--search').on('blur', function() {
        var $input = $(this);
        var $filterInput = $input.closest('.filter-input');
        $filterInput.prev('.filter-input__placeholder').remove();

        $filterInput.css({
            position: '',
            top: '',
            left: '',
            width: '',
        });
    });
});