function openModal(modalId) {
    $('.modal').removeClass('modal--open');
    const $modal = $(`#${modalId}`);
    $modal.addClass('modal--open');

    $modal.find('.modal__close-btn, .modal__overlay').on('click', function() {
        $modal.removeClass('modal--open');
    });

    initAllSliders($modal);
}

function closeModal(modalId) {
    const $modal = $(`#${modalId}`);
    $modal.removeClass('modal--open');
}

function closeThisModal(element) {
    const $modal = $(element).closest('.modal');
    $modal.removeClass('modal--open');
}
