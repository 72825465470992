class CustomSelect {
    constructor(selector) {
        this.$select = $(selector);
        this.label = this.$select.data('label') || 'Статус';
        this.searchable = this.$select.data('searchable') || false;
        this.clearable = this.$select.data('clearable') !== undefined ? this.$select.data('clearable') : true;
        this.placeholder = this.$select.data('placeholder') || 'Все';
        this.init();
    }

    init() {
        this.hideOriginalSelect();
        this.createCustomSelect();
        this.populateCustomOptions();
        this.bindEvents();
        this.handleClickOutside();
        this.updateInitialSelect();
    }

    hideOriginalSelect() {
        this.$select.hide();
    }

    createCustomSelect() {
        let searchInputHTML = '';
        if (this.searchable) {
            searchInputHTML = `
                <div class="filter-select__option-search">
                    <input name="search" placeholder="Поиск" type="text">
                </div>
            `;
        }

        const customSelectHTML = `
            <div class="filter-select filter-field">
                <div class="filter-field__input">
                    <div class="filter-field__label">${this.label}</div>
                    <div class="filter-field__selected"><span></span>${this.placeholder}</div>
                    ${this.clearable ? '<div class="filter-field__clear"><span></span>Сбросить</div>' : ''}
                    <div class="filter-field__arrow"></div>
                </div>
                <div class="filter-select__options">
                    ${searchInputHTML}
                </div>
            </div>
        `;
        this.$select.after(customSelectHTML);
        this.$customSelect = this.$select.next('.filter-select');

        this.createProxyBox();
    }

    populateCustomOptions() {
        const $optionsContainer = this.$customSelect.find('.filter-select__options')[0];
        const fragment = document.createDocumentFragment();

        this.$select.find('option').each((index, option) => {
            const optionText = option.textContent;
            const optionValue = option.value;
            const modifierClass = option.getAttribute('data-modifier-class');
            const isDotted = option.getAttribute('data-dotted') === 'true';

            const customOptionItem = document.createElement('div');
            customOptionItem.classList.add('filter-select__option-item');
            customOptionItem.setAttribute('data-value', optionValue);

            if (modifierClass) {
                customOptionItem.setAttribute('data-modifier-class', modifierClass);
            }

            if (isDotted) {
                customOptionItem.setAttribute('data-dotted', isDotted);
            }

            const optionSpan = document.createElement('span');
            optionSpan.textContent = optionText;

            customOptionItem.appendChild(optionSpan);
            fragment.appendChild(customOptionItem);
        });

        $optionsContainer.appendChild(fragment);
    }

    bindEvents() {
        this.$customSelect.find('.filter-field__input').on('click', () => {
            this.$customSelect.toggleClass('filter-field--active');
            this.checkoutView();

            if (this.searchable) {
                this.clearSearchInput();
            }
        });

        this.$customSelect.find('.filter-select__option-item').on('click', (event) => {
            const selectedText = $(event.currentTarget).text();
            const selectedValue = $(event.currentTarget).data('value');
            const modifierClass = $(event.currentTarget).data('modifier-class');
            const isDotted = $(event.currentTarget).data('dotted');
            this.updateSelect(selectedText, selectedValue, false, modifierClass, isDotted);
        });

        if (this.searchable) {
            this.$customSelect.find('input[name="search"]').on('input', (event) => {
                const searchTerm = $(event.currentTarget).val().toLowerCase();
                if (searchTerm === '') {
                    this.$customSelect.find('.filter-select__option-item').show();
                } else {
                    this.$customSelect.find('.filter-select__option-item span').each((index, span) => {
                        const optionText = $(span).text().toLowerCase();
                        $(span).parent().toggle(optionText.indexOf(searchTerm) > -1);
                    });
                }
            });
        }

        this.$customSelect.find('.filter-field__clear').on('click', (e) => {
            e.stopPropagation();
            this.$customSelect.find('.filter-field__selected').html(`<span></span>${this.placeholder}`);
            this.$customSelect.removeClass('filter-field--selected');
            const currentModifierClass = this.$customSelect.data('modifier-class');
            if (currentModifierClass) {
                this.$customSelect.removeClass(currentModifierClass);
            }
            this.updateOriginalSelect('');
        });
    }

    clearSearchInput() {
        const $searchInput = this.$customSelect.find('input[name="search"]');
        $searchInput.val('');
        this.$customSelect.find('.filter-select__option-item').show();
    }

    handleClickOutside() {
        $(document).on('click', (event) => {
            if (!$(event.target).closest('.filter-select').length) {
                this.$customSelect.removeClass('filter-field--active');
                this.checkoutView(true);
            }
        });
    }

    updateOriginalSelect(selectedValue) {
        this.$select.val(selectedValue).change();

        const element = this.$select.get(0);
        element.dispatchEvent(new Event('change', {bubbles: true}));
    }

    updateInitialSelect() {
        const selectedValue = this.$select.find('option[selected]').val();
        if (selectedValue) {
            const selectedText = this.$select.find(`option[value="${selectedValue}"]`).text();
            const modifierClass = this.$select.find(`option[value="${selectedValue}"]`).data('modifier-class');
            const isDotted = this.$select.find(`option[value="${selectedValue}"]`).data('dotted');
            this.updateSelect(selectedText, selectedValue, true, modifierClass, isDotted);
        }
    }

    updateSelect(selectedText, selectedValue, init = false, modifierClass = null, isDotted = false) {
        this.$customSelect.find('.filter-field__selected').html(`<div class="filter-field__dot"></div>${selectedText}`);
        this.$customSelect.addClass('filter-field--selected').removeClass('filter-field--active');

        const currentModifierClass = this.$customSelect.data('modifier-class');
        if (currentModifierClass) {
            this.$customSelect.removeClass(currentModifierClass);
        }

        if (modifierClass) {
            this.$customSelect.addClass(modifierClass);
            this.$customSelect.data('modifier-class', modifierClass);
        }

        const currentIsDotted = this.$customSelect.data('dotted');
        if (currentIsDotted) {
            this.$customSelect.removeClass('filter-field--dotted');
        }

        if (isDotted) {
            this.$customSelect.addClass('filter-field--dotted');
            this.$customSelect.data('dotted', isDotted ? 'true' : 'false');
        }

        this.checkoutView(init);
        this.updateOriginalSelect(selectedValue);
    }

    checkoutView(init = false) {
        if (this.$customSelect.hasClass('filter-field--active')) {
            const originalPosition = this.$customSelect.parent().offset();
            this.$customSelect.parent().css({
                position: 'absolute',
                left: originalPosition.left + 'px',
                top: originalPosition.top + 'px',
                width: this.$select.outerWidth() + 'px',
            });
            this.showProxyBox();
        } else {
            this.$customSelect.parent().css({
                position: '',
                left: '',
                top: '',
                width: init ? '' : this.$select.outerWidth() + 'px',
            });
            this.hideProxyBox();
        }
    }

    createProxyBox() {
        this.$proxyBox = $('<div class="filter-input__proxy-box"></div>').css({
            position: 'absolute',
            display: 'none',
        });
        this.$customSelect.parent().after(this.$proxyBox);
    }

    showProxyBox() {
        this.$proxyBox.css({
            position: '',
            display: '',
            width: '100%',
            height: this.$select.outerHeight() + 'px',
        });

        this.$checkAndMoveElemenIntervalId = setInterval(
          this.fixPosition,
          1,
          this.$customSelect,
          this.$proxyBox,
        );
    }

    hideProxyBox() {
        this.$proxyBox.css({
            position: 'absolute',
            display: 'none',
            width: '',
            height: '',
        });
        clearInterval(this.$checkAndMoveElemenIntervalId);
    }

    fixPosition(customSelect, proxyBox) {
        if (
          customSelect.parent().offset().left !== proxyBox.offset().left
          || customSelect.parent().offset().top !== proxyBox.offset().top
        ) {
            customSelect.parent().offset(proxyBox.offset());
        }
    }
}

$(document).ready(function() {
    $('select.filter-input__select').each(function() {
        new CustomSelect(this);
    });
});
