initSlider = (el) => {
    new Glide(el, {
        type: 'slider',
        perView: el.dataset.items ?? 4.4,
        rewind: false,
        gap: 16,
    }).mount();
};

initAllSliders = (el = null) => {
    if (el === null) {
        el = document;
    }

    $(el).find('[data-slider]').each(function(i, el) {
        initSlider(el);
    });
};

$(document).ready(function() {
    initAllSliders();
});
