$(document).ready(function() {
    const $navProfile = $('.nav-profile');
    const $navProfileAvatar = $('.nav-profile__avatar');
    const $navProfileMenu = $('.nav-profile__menu');

    $navProfileAvatar.on('click', function(event) {
        $navProfile.toggleClass('nav-profile--open');
        event.stopPropagation();
    });

    $(document).on('click', function(event) {
        if (!$navProfileMenu.is(event.target) && $navProfileMenu.has(event.target).length === 0) {
            $navProfile.removeClass('nav-profile--open');
        }
    });

    $navProfileMenu.on('click', function(event) {
        event.stopPropagation();
    });
});