class FilterDatepicker {
    constructor(selector) {
        this.$input = $(selector);
        this.label = this.$input.data('label') || 'Дата';
        this.placeholder = 'Все';
        this.currentDate = this.$input.val() ? new Date(this.$input.val()) : null;
        this.init();
    }

    init() {
        this.hideOriginalInput();
        this.createCustomDatepicker();
        this.bindEvents();
        this.handleClickOutside();
        this.updateDatepicker();
    }

    hideOriginalInput() {
        this.$input.hide();
    }

    createCustomDatepicker() {
        const datepickerHTML = `
            <div class="filter-datepicker filter-field">
                <div class="filter-field__input">
                    <div class="filter-field__label">${this.label}</div>
                    <div class="filter-field__selected"><span></span>${this.placeholder}</div>
                    <div class="filter-field__clear"><span></span>Сбросить</div>
                    <div class="filter-field__calendar"></div>
                </div>
                <div class="form-datepicker__card">
                    <div class="form-datepicker__header">
                        <button class="form-datepicker__select">Месяц</button>
                        <button class="form-datepicker__select">Год</button>
                    </div>
                    <div class="form-datepicker__calendar"></div>
                </div>
            </div>
        `;
        this.$input.after(datepickerHTML);
        this.$customDatepicker = this.$input.next('.filter-datepicker');
        this.$calendarContainer = this.$customDatepicker.find('.form-datepicker__calendar');
        this.$monthButton = this.$customDatepicker.find('.form-datepicker__select:first');
        this.$yearButton = this.$customDatepicker.find('.form-datepicker__select:last');
        this.$valueDisplay = this.$customDatepicker.find('.filter-field__selected');
        this.$clearButton = this.$customDatepicker.find('.filter-field__clear');
        this.$datepickerCard = this.$customDatepicker.find('.form-datepicker__card');

        this.initializeBootstrapDatepicker();
    }

    initializeBootstrapDatepicker() {
        this.$calendarContainer.datepicker({
            weekStart: 1,
            maxViewMode: 2,
            language: 'ru',
            todayHighlight: true,
            autoclose: true,
        }).
          on('changeDate', (e) => this.handleDateChange(e)).
          on('changeMonth', (e) => this.handleMonthChange(e)).
          on('changeYear', (e) => this.handleYearChange(e));

        this.$monthButton.text(this.getMonthName(this.currentDate ?? new Date()));
        this.$yearButton.text((this.currentDate ?? new Date()).getFullYear());
    }

    bindEvents() {
        this.$customDatepicker.find('.filter-field__input').on('click', () => {
            this.$customDatepicker.toggleClass('filter-field--active');
            this.positionDatepickerCard();
        });

        this.$clearButton.on('click', (e) => {
            e.stopPropagation();
            this.clearDate();
        });

        this.$monthButton.on('click', () => this.toggleMonthSelect());
        this.$yearButton.on('click', () => this.toggleYearSelect());
    }

    positionDatepickerCard() {
        const inputOffset = this.$customDatepicker.find('.filter-field__input').offset();
        const inputHeight = this.$customDatepicker.find('.filter-field__input').outerHeight();
        const windowWidth = $(window).width();
        const cardWidth = this.$datepickerCard.outerWidth();

        this.$datepickerCard.css({
            position: 'fixed',
            top: inputOffset.top + inputHeight,
            left: Math.min(inputOffset.left, windowWidth - cardWidth - 20),
        });
    }

    handleClickOutside() {
        $(document).on('click', (event) => {
            if (!$(event.target).closest('.filter-datepicker').length) {
                this.$customDatepicker.removeClass('filter-field--active');
            }
        });
    }

    updateDatepicker() {
        if (this.currentDate) {
            this.setDate(this.currentDate);
        }
    }

    setDate(date) {
        this.currentDate = date;
        const formattedDate = this.formatDate(date);
        this.$valueDisplay.html(`${formattedDate}`);
        this.$input.val(formattedDate);
        this.$customDatepicker.addClass('filter-field--selected');
        this.$customDatepicker.removeClass('filter-field--active');
    }

    clearDate() {
        this.currentDate = null;
        this.$valueDisplay.html(`${this.placeholder}`);
        this.$input.val('');
        this.$customDatepicker.removeClass('filter-field--selected');
        this.$customDatepicker.removeClass('filter-field--active');
    }

    formatDate(date) {
        const day = ('0' + date.getDate()).slice(-2);
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const year = date.getFullYear();
        return `${day}.${month}.${year}`;
    }

    getMonthName(date) {
        const monthNames = [
            'Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь',
            'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь',
        ];
        return monthNames[date.getMonth()];
    }

    toggleMonthSelect() {
        if (this.$monthButton.hasClass('form-datepicker__select--open')) {
            this.closeMonthSelect();
        } else {
            this.openMonthSelect();
        }
    }

    openMonthSelect() {
        try {
            this.closeYearSelect();
            this.$monthButton.addClass('form-datepicker__select--open');
            this.$calendarContainer.find('.datepicker-months .datepicker-switch').click();
        } catch (e) {

        }
    }

    closeMonthSelect() {
        try {
            this.$monthButton.removeClass('form-datepicker__select--open');
            this.$calendarContainer.find('.datepicker-months .month.active').click();
        } catch (e) {

        }
    }

    toggleYearSelect() {
        if (this.$yearButton.hasClass('form-datepicker__select--open')) {
            this.closeYearSelect();
        } else {
            this.openYearSelect();
        }
    }

    openYearSelect() {
        try {
            this.closeMonthSelect();
            this.$yearButton.addClass('form-datepicker__select--open');
            this.populateYears();
            this.$calendarContainer.find('.datepicker-days .datepicker-switch').click();
            this.$calendarContainer.find('.datepicker-months .datepicker-switch').click();
            this.scrollToActiveYear();
        } catch (e) {

        }
    }

    closeYearSelect() {
        try {
            this.$yearButton.removeClass('form-datepicker__select--open');
            this.$calendarContainer.find('.datepicker-years .year.active').click();
        } catch (e) {

        }
    }

    populateYears() {
        const $yearTable = this.$calendarContainer.find('.datepicker-years tbody td');
        let html = '';

        for (let year = 1900; year <= 2030; year++) {
            html += `<span class="year${(this.currentDate ?? new Date()).getFullYear() === year ?
              ' active' :
              ''}">${year}</span>`;
        }

        $yearTable.html(html);
    }

    scrollToActiveYear() {
        const $activeYear = this.$calendarContainer.find('.year.active').first();
        if ($activeYear.length) {
            const $yearTableBody = this.$calendarContainer.find('.datepicker-years');
            const containerTop = $yearTableBody.offset().top;
            const containerHeight = $yearTableBody.height();
            const activeYearTop = $activeYear.offset().top;
            const activeYearHeight = $activeYear.outerHeight();
            const scrollTop = $yearTableBody.scrollTop();

            const visibleAreaTop = containerTop + scrollTop;
            const visibleAreaBottom = visibleAreaTop + containerHeight;

            if (activeYearTop < visibleAreaTop || activeYearTop + activeYearHeight > visibleAreaBottom) {
                const newScrollTop = scrollTop +
                  (activeYearTop - containerTop - (containerHeight / 2) + (activeYearHeight / 2));
                $yearTableBody.scrollTop(newScrollTop);
            }
        }
    }

    handleDateChange(e) {
        const newDate = new Date(e.date);
        this.setDate(newDate);
    }

    handleMonthChange(e) {
        this.$monthButton.text(this.getMonthName(e.date));
        this.$monthButton.removeClass('form-datepicker__select--open');
    }

    handleYearChange(e) {
        this.$yearButton.text(e.date.getFullYear());
        this.$yearButton.removeClass('form-datepicker__select--open');
        this.$monthButton.addClass('form-datepicker__select--open');
    }
}

$(document).ready(function() {
    $('input.filter-input__date').each(function() {
        new FilterDatepicker(this);
    });
});