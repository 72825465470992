$(document).ready(function() {
    $('.avatar__attach').on('click', function() {
        $(this).parent().find('.avatar__input').click();
    });

    $('.avatar__input').on('change', function(event) {
        const file = event.target.files[0];
        const $img = $(this).parent().find('.avatar__image');

        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                $img.attr('src', e.target.result);
            };

            reader.readAsDataURL(file);

            return;
        }

        $img.attr('src', $img.data('default-src'));
    });
});