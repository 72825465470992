$(document).ready(function() {
    $('.popup-trigger').on('click', function(e) {
        e.stopPropagation();
        $('.popup').removeClass('popup--open');
        var $popupOwner = $(this).closest('.popup-owner');
        var $popup = $popupOwner.find('.popup');

        var rect = $popupOwner[0].getBoundingClientRect();
        var popupWidth = $popup.outerWidth();
        var popupHeight = $popup.outerHeight();
        var windowWidth = $(window).width();
        var windowHeight = $(window).height();
        var offset = 10; // отступ от края экрана

        var top = rect.top + $popupOwner.outerHeight() * 0.95;
        var left = rect.left - popupWidth + $popupOwner.outerWidth();

        if ($popup.hasClass('popup--center')) {
            left = rect.left - popupWidth + ($popupOwner.outerWidth() / 2);
        }

        if (left < offset) {
            left = offset;
        } else if (left + popupWidth > windowWidth - offset) {
            left = windowWidth - popupWidth - offset;
        }

        if (top < offset) {
            top = offset;
        } else if (top + popupHeight > windowHeight - offset) {
            top = windowHeight - popupHeight - offset;
        }

        $popup.css({
            top: top + 'px',
            left: left + 'px',
        });

        $popup.addClass('popup--open');

        $(document).on('click', function(event) {
            if (!$(event.target).closest('.popup').length && !$(event.target).closest('.popup-trigger').length) {
                $popup.removeClass('popup--open');
            }
        });
    });

    $(window).on('scroll', function() {
        $('.popup').removeClass('popup--open');
    });
});