$(document).ready(function() {
    initializeFormSelects();
    observeDOMChanges();

    function initializeFormSelects() {
        $('.form-field select').each(function() {
            if (!$(this).data('formSelectInitialized')) {
                new FormSelect(this);
                $(this).data('formSelectInitialized', true);
            }
        });
    }

    function observeDOMChanges() {
        const observer = new MutationObserver((mutationsList) => {
            for (const mutation of mutationsList) {
                if (mutation.type === 'childList') {
                    $(mutation.addedNodes).each(function() {
                        if ($(this).find('.form-field select').length) {
                            initializeFormSelects();
                        }
                    });
                }
            }
        });

        observer.observe(document.body, {
            childList: true,
            subtree: true,
        });
    }
});

class FormSelect {
    constructor(selectElement) {
        this.$select = $(selectElement);
        this.$field = this.$select.closest('.form-field');
        this.$group = this.$select.closest('.form-field__group');
        this.$label = this.$group.find('label[for="' + this.$select.attr('id') + '"]');

        this.createCustomSelect();
        this.attachEvents();
    }

    createCustomSelect() {
        const options = this.$select.find('option').map((index, option) =>
          `<div class="form-select__option" data-value="${$(option).val()}">${$(option).text()}</div>`,
        ).get().join('');

        const customSelectHTML = `
            <div class="form-select">
                <div class="form-select__input">
                    <input class="form-select__search" type="text" placeholder="Найти">
                    <div class="form-select__arrow"></div>
                </div>
                <div class="form-select__options">
                    ${options}
                    <div class="form-select__divider"></div>
                </div>
            </div>
        `;

        this.$group.prepend(customSelectHTML);
        this.updateSelectVisibility();
        this.$customSelect = this.$group.find('.form-select');
        this.$input = this.$customSelect.find('.form-select__search');
        this.$options = this.$customSelect.find('.form-select__options');
        this.$arrow = this.$customSelect.find('.form-select__arrow');
    }

    attachEvents() {
        this.$arrow.on('click', () => this.toggleSelect());
        this.$options.on('click', '.form-select__option', (event) => this.selectOption(event));
        this.$input.on('input', () => this.filterOptions());
    }

    toggleSelect() {
        this.$field.toggleClass('form-field--disabled');
        this.updateSelectVisibility();
    }

    selectOption(event) {
        const $option = $(event.target);
        const value = $option.data('value');
        const text = $option.text();
        const placeholderOption = this.$options.find('[data-value=""]').first();

        if (placeholderOption && this.$select.val() == value) {
            this.$select.val(placeholderOption.data('value')).change();
            this.$input.val(placeholderOption.text());
        } else {
            this.$select.val(value).change();
            this.$input.val(text);
        }

        this.$field.addClass('form-field--disabled');
        this.updateSelectVisibility();
    }

    filterOptions() {
        const searchText = this.$input.val().toLowerCase();
        this.$options.find('.form-select__option').each((index, option) => {
            const $option = $(option);
            const text = $option.text().toLowerCase();
            $option.toggle(text.includes(searchText));
        });
    }

    updateSelectVisibility() {
        if (this.$field.hasClass('form-field--disabled')) {
            this.$select.show();
        } else {
            this.$select.hide();
        }
    }
}
