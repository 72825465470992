/**
 * @param {Object} options - Options for configuring the modal.
 * @param {string} options.title - Title displayed in the modal header.
 * @param {string} options.contentHtml - HTML content for the modal body.
 * @param {Object} options.successBtn - Configuration for the success button.
 * @param {string} options.successBtn.text - Text displayed on the success button.
 * @param {string} options.successBtn.type - CSS class modifier for styling the success button.
 * @param {Function} options.successFunction - Function to execute on success button click.
 *
 * @method generateUniqueId() - Generates a unique ID for the modal.
 * @method init() - Initializes the modal by generating HTML, binding events, and opening it.
 * @method openModal() - Opens the modal by adding the 'modal--open' class.
 */
class ConfirmModal {
    constructor(options) {
        this.title = options.title || 'Подтверждение';
        this.contentHtml = options.contentHtml || `
            <div class="modal__text">
                <div>Вы действительно хотите совершить данное действие?</div>
            </div>
        `;
        this.successBtn = options.successBtn || {text: 'Подтвердить', type: 'btn--error'};
        this.successFunction = options.successFunction || function() {
        };
        this.modalId = this.generateUniqueId();
        this.init();
    }

    generateUniqueId() {
        let id;
        do {
            id = 'modal_' + Math.random().toString(36).substr(2, 9);
        } while (document.getElementById(id));
        return id;
    }

    init() {
        const modalHtml = `
            <div id="${this.modalId}" class="modal modal--confirm">
                <div class="modal__overlay"></div>
                <div class="modal__content">
                    <div class="modal__header">
                        <div class="modal__title">${this.title}</div>
                        <button class="modal__close-btn">
<!--                        //TODO fix route -->
                            <img src="../../assets/images/icons/cross.svg" alt="">
                        </button>
                    </div>
                    <div class="modal__body">
                        ${this.contentHtml}
                    </div>
                    <div class="modal__footer">
                        <div class="modal__actions">
                            <button class="modal__btn btn btn--light" onclick="closeThisModal(this)">Отменить</button>
                            <button class="modal__btn btn ${this.successBtn.type}" onclick="${this.modalId}_successFunction()">
                                ${this.successBtn.text}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        `;
        $('body').append(modalHtml);
        window[`${this.modalId}_successFunction`] = () => {
            closeModal(this.modalId);
            this.successFunction();
        };
        openModal(this.modalId);
        this.observeModal();
    }

    observeModal() {
        const targetNode = document.getElementById(this.modalId);
        const observer = new MutationObserver(mutationsList => {
            for (let mutation of mutationsList) {
                if (mutation.type === 'attributes' && !targetNode.classList.contains('modal--open')) {
                    targetNode.remove();
                    observer.disconnect();
                    break;
                }
            }
        });
        observer.observe(targetNode, {attributes: true});
    }
}