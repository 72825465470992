$(document).ready(function() {
    $('.file-input').each(function() {
        var $fileInput = $(this).find('.file-input__input');
        var $fileInputContainer = $(this);
        var $attachedFilesContainer = $(this).find('.file-input__attached-files');

        // Обработка dragover
        $fileInputContainer.on('dragover', function(event) {
            event.preventDefault();
            event.stopPropagation();
            $(this).addClass('dragover');
        });

        // Обработка dragleave
        $fileInputContainer.on('dragleave', function(event) {
            event.preventDefault();
            event.stopPropagation();
            $(this).removeClass('dragover');
        });

        // Обработка drop
        $fileInputContainer.on('drop', function(event) {
            event.preventDefault();
            event.stopPropagation();
            $(this).removeClass('dragover');
            var files = event.originalEvent.dataTransfer.files;
            handleFiles(files);
        });

        // Обработка кнопки загрузки
        $(this).find('.file-input__action button').on('click', function() {
            $fileInput.click();
        });

        // Обработка выбора файлов
        $fileInput.on('change', function(event) {
            var files = event.target.files;
            handleFiles(files);
        });

        // Функция для обработки файлов
        function handleFiles(files) {
            $attachedFilesContainer.empty();

            $.each(files, function(index, file) {
                var reader = new FileReader();
                reader.onload = function(e) {
                    var fileName = file.name;
                    var fileItem = `
                        <div class="file-input__attached-file tag" data-file-name="${fileName}">
                            <span>${fileName}</span>
                            <div class="tag__icon-container">
                                <img class="tag__icon file-input__remove-attach" alt="Remove" src="../../assets/images/icons/cross.svg">
                            </div>
                        </div>
                    `;
                    $attachedFilesContainer.append(fileItem);
                };
                reader.readAsDataURL(file);
            });
        }

        $attachedFilesContainer.on('click', '.file-input__remove-attach', function() {
            $(this).closest('.file-input__attached-file').remove();
        });
    });
});